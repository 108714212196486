import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";


function App() {
    const { unityProvider } = useUnityContext({
        loaderUrl: "Build/Build_0.loader.js",
        dataUrl: "Build/Build_0.data",
        frameworkUrl: "Build/Build_0.framework.js",
        codeUrl: "Build/Build_0.wasm",
    });

    return (
        <div className="flex place-content-center content-center h-[100vh] p-40">
            <Unity unityProvider={unityProvider} style={{ width: 1280, height: 720 }} />
        </div>

    );
}


export default App;
